import { css, cva } from "ui/css";

export const sliderCss = css({
  maxWidth: "240px",
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "normal",
  overflowX: "auto",
  paddingInline: "0px",
  scrollBehavior: "smooth",
  scrollSnapStop: "always",
  scrollSnapType: "x mandatory",
  overscrollBehaviorX: "none",
  overscrollBehaviorY: "auto",
  scrollbarWidth: "none",
  height: "100%",
  "&:-webkit-scrollbar": {
    display: "none",
  },
});

export const slideCss = css({
  flex: "0 0 100%",
  maxWidth: "none",
  listStyleType: "none",
  scrollSnapAlign: "start",
  scrollSnapStop: "always",
});

export const NavigationContainerCss = css({
  position: "absolute",
  width: "100%",
  bottom: "-32px",
  right: 0,
  padding: "0px 8px",
});

export const NavigationKeyCss = cva({
  base: {
    cursor: "pointer",
    padding: "4px",
    backgroundColor: "transparent",

    border: "1px solid {colors.gray}",
    borderRadius: "12px",
    _disabled: {
      cursor: "not-allowed",
    },
  },
  variants: {
    direction: {
      left: {
        transform: "rotate(90deg)",
      },
      right: {
        transform: "rotate(270deg)",
      },
    },
  },
});

export const SlideIndicatorText = cva({
  base: {
    fontSize: "14px",
  },
  variants: {
    status: {
      enabled: {
        opacity: 1,
      },
      disabled: {
        opacity: 0.5,
      },
    },
  },
});
